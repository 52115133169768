<template>
  <div id='home'>
    <PageTitle text='ホーム' />
    <SectionTitle icon='mdi-football-helmet' text='JPFFとは？' />
    <p>JPFF（日本プライベートフットボール協会）は、日本におけるアメリカンフットボールの普及を目的として1978年に設立されました。</p>
    <p>こちらのページでは、現在日本で活動されているクラブチームの試合日程、結果、過去の戦績などのチーム情報や、さまざまなイベント情報、関係団体の情報などがご覧になれます。</p>
    <p>
      協会に加盟を希望する団体の方は
      <router-link to="/join">こちら</router-link>
      をご覧ください。
    </p>
    <SectionTitle icon='mdi-football-helmet' text='協会からのお知らせ' />
    <p>内容を見るにはタイトルをクリックしてください。JPFF公式のブログが開きます。</p>
    <v-list two-line>
      <template v-for="(item, index) in newsItems">
        <v-list-item
          :key="`list-item-${index}`"
          :href="item.href"
          target='_blank'
        >
          <v-list-item-content>
            <v-list-item-title v-text='item.title' />
            <v-list-item-subtitle v-text='item.publishedAt' />
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < newsItems.length - 1"
          :key="`divider-${index}`"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import Parser from 'rss-parser'
import { format } from 'date-fns'

import PageTitle from '@/components/PageTitle'
import SectionTitle from '@/components/SectionTitle'

export default {
  components: { PageTitle, SectionTitle },
  data: () => ({
    newsItems: [],
  }),
  created () {
    const parser = new Parser()

    parser.parseURL('https://jpff.hatenablog.com/rss', (error, feed) => {
      this.newsItems = feed.items.map((item) => {
        const publishedAt = new Date(item.pubDate)

        return {
          title: item.title,
          href: item.link,
          publishedAt: format(publishedAt, 'yyyy/MM/dd HH:mm')
        }
      })
    })
  },
}
</script>
